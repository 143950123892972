<template>
  <div>
    <app-stage asset="/assets/stages/flower.jpg" :height="Number(200)"></app-stage>
    <v-container class="fixed-width">
      <v-row>
        <v-col>
          <h2 id="about" class="display-1 text-center mb-4">Imprint</h2>

          <h3>Copyright</h3>

          <p>
          The copyright of the content, images, pictures and other files on this website
          lies with the artist (Noemi Galavotti). Any duplication or use in other electronic
          or printed publications is not permitted without the expressed consent of the artist
          (Noemi Galavotti).
          </p>
          <p>
          Contact:<br/>
          ColorFoundry<br/>
          Noemi Galavotti<br/>
          Switzerland
          </p>
          <p>
          E-Mail: <a href="mailto:info@colorfoundry.art">info@colorfoundry.art</a>
          </p>

          <h3>Cookies &amp; Third Party Tools</h3>

          <p>ColorFoundry.art uses Google Analytics, a web analysis service of Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, <a href="www.google.com" target="_new">www.google.com</a> (“Google Analytics” or “Google”). Google Analytics employs cookies that are stored to your computer in order to facilitate an analysis of your use of the site. The information generated by these cookies, such as time, place and frequency of your visits to our site, is transmitted to Google’s location in the US and stored there.</p>

          <p>In using Google Analytics our website employs the extension “anonymizeIp”. In doing so, Google abbreviates and thereby anonymizes your IP address before transferring it from EU/EEA member states. Google uses this information to analyze your use of our site, to compile reports for us on internet activity and to provide other services relating to our website.</p>

          <p>Google may also transfer this information to third parties where required to do so by law or where such third parties process this data on Google’s behalf. Google states that it will in never associate your IP address with other data held by Google. You can prevent cookies from being installed by adjusting the settings on your browser software accordingly as noted elsewhere in this Privacy Policy. You should be aware, however, that by doing so you may not be able to make full use of all the functions of our website.</p>

          <p>Google Analytics also offers a deactivation add-on for most current browsers that provides you with more control over what data Google can collect on websites you access. The add-on tells the JavaScript (ga.js) used by Google Analytics not to transmit any information about website visits to Google Analytics. However, the browser deactivation add-on offered by Google Analytics does not prevent information from being transmitted to us or to other web analysis services we may engage.</p>

          <p>Google Analytics also uses electronic images known as web beacons (sometimes called single pixel gifs) and are used along with cookies to compile aggregated statistics to analyze how our site is used.</p>

          <p>You can find additional information on how to install the browser add-on referenced above at the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_new">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</p>

          <p>For the cases in which personal data is transferred to the US, Google has self-certified pursuant to the EU-US Privacy Shield (<a href="https://www.privacyshield.gov/EU-US-Framework" target="_new">https://www.privacyshield.gov/EU-US-Framework</a>).</p>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import AppStage from "@/components/layout/AppStage"

export default {
  name: 'Home',

  components: {
    AppStage
  }
}

</script>
